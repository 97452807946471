/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GlobalStyles } from 'twin.macro'
import Header from "./header"
import "./layout.css"
import Footer from "./footer"
import { config } from "@fortawesome/fontawesome-svg-core";
import CookieConsent from "react-cookie-consent";



config.autoAddCss = false;



const Layout = ({ children }) => {
  <div>
    <GlobalStyles />
    {children}
  </div>
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
            menuLinks {
                name
                link
            }
            }
        }
      }
    `)





  return (
    <>
      <div className={"flex flex-col"}>
      <div
        id="outer-container">

      <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title} />

      {/*<Menu*/}
      {/*  pageWrapId={ "page-wrap" }*/}
      {/*  outerContainerId={ "outer-container" }*/}
      {/*  right*/}
      {/*  width={ 280 }*/}
      {/*  styles={ styles }*/}
      {/*/>*/}
        <main className=""
        id={"page-wrap"}
        >{children}
        </main>
      </div>
      <Footer menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title} />

        {/*<CookieConsent*/}
        {/*  debug={true}*/}
        {/*  location={"bottom"}*/}
        {/*  disableButtonStyles={true}*/}
        {/*  disableStyles={true}*/}
        {/*  buttonText={"Accept"}*/}
        {/*  containerClasses={"flex justify-center bg-delayed border border-white  text-white fixed bottom-0 right-0"}*/}
        {/*  contentClasses={"flex flex-grow-0 justify-end items-center mx-4"}*/}
        {/*  buttonWrapperClasses={"flex justify-center w-20"}*/}
        {/*  buttonClasses={"text-white hover:text-orange border border-white hover:border-orange mt-2 mb-2 p-2 h-10 bg-orange hover:bg-white focus:outline-none rounded-none  hover: active:shadow-3DClicked active:top-5px active:-left-5px font-bold transition-all"}*/}
        {/*>*/}
        {/*  This website uses cookies to enhance the user experience.</CookieConsent>*/}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
