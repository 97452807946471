import * as React from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import OrangeButtonWithWhiteLineShadow from "./orangeButtonWithWhiteLineShadow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faLinkedinIn, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import { Script } from "gatsby";


function Footer({ siteTitle, menuLinks }) {

  return (
  <footer className='flex flex-col flex-wrap lg:flex-row justify-center footer-background p-5 w-full'>
    <div className={"flex flex-col sm:flex-row"}>
      <ul className={"flex flex-col sm:flex-row sm:justify-center sm:mx-auto ml-0 my-auto text-black text-sm"}>
        {menuLinks.map((link) => (
          <li key={link.name} className={"mb-0 list-none mx-4"}>
            <Link className={"text-black hover:text-orange focus:underline"} to={link.link}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
    {/*<ul*/}
    {/*  className={"flex flex-col ml-0 my-auto pt-12 text-black"}*/}
    {/*>*/}

    {/*<div className={"pt-12 xl:pt-0 flex justify-center"}>*/}
    {/*  <OrangeButtonWithWhiteLineShadow*/}
    {/*    onClick={() => {*/}
    {/*      navigate("/free-site-evaluation")*/}
    {/*    }}*/}
    {/*    className={"my-auto"}*/}
    {/*  >Free Site Evaluation &nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;*/}
    {/*  </OrangeButtonWithWhiteLineShadow>*/}
    {/*  /!*</ul>*!/*/}
    {/*</div>*/}
    <div className={"flex flex-col items-center"}>
      <ul className={"flex flex-col ml-0 my-auto pt-8 lg:pt-0 xl:pt-0 pl-0 xl:pl-8 "}>
        <div className={"flex text-4xl justify-center sm:items-center my-auto "}>
          <a target={"_blank"} href={"https://www.twitter.com/zycada"} className={"px-2"}>
            <StaticImage src={"../images/twitter.svg"} alt={"LinkedIn icon"}></StaticImage>
            {/*<FontAwesomeIcon className={"mx-4 focus:underline zycadaGradientText"} icon={faTwitter}/>*/}
          </a>
          <a target={"_blank"} href={"https://www.linkedin.com/company/zycada-networks"} className={"px-2"}>
            <StaticImage src={"../images/linkedin.svg"} alt={"LinkedIn icon"}></StaticImage>

            {/*<FontAwesomeIcon className={"mx-4 focus:underline"} icon={faLinkedin}/>*/}
          </a>
        </div>
      </ul>
    </div>
    <div className={"flex justify-center text-sm ml-0 sm:mx-4 my-auto pt-8 lg:pt-0 xl:pt-0 xl:pl-8 "}>
      <Link to={"/privacy-policy"}>Privacy Policy</Link>
      &nbsp; &nbsp; &nbsp;
      <Link to={"/terms-of-use"}>Terms of Use</Link>
    </div>

    <div
      className={"w-full flex flex-col items-center justify-center text-sm ml-0 sm:mx-4 my-auto pt-8 lg:pt-8 xl:pl-8 "}
    >
      <p className={"my-0"}>All rights Reserved © Palo Alto Networks, 2024</p>
    </div>

  </footer>
);
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
