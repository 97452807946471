import * as React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

const ThankYouPage = () => (
  <Layout>
    <div className="flex flex-col flex-wrap justify-start min-h-screen items-center pt-32">
      <SEO title="Thank you!" />
      <h1 className={'text-center'}>
        <span className={'text-orange'}>Thank you!</span>
      </h1>
      <p className={'text-center mx-12'}>Your submission was successful. A team member will be in touch soon.</p>
      <Link to={'/'}>
        <button
          className={
            'relative text-white  p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 '
          }
        >
          Return Home &nbsp;&nbsp;
          <FontAwesomeIcon icon={faArrowRight} />
          &nbsp;&nbsp;
        </button>
      </Link>
    </div>
  </Layout>
);

export default ThankYouPage;
